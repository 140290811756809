
































































































































import {Component, Vue} from "vue-property-decorator";
import {PairingRecordDetailDto} from "@/api/appService";
import api from "@/api";

@Component({
  name: "cmsContentDetail",
  components: {},
})
export default class cmsContentDetail extends Vue {
  dataId?: number;
  detail: PairingRecordDetailDto = {
    bid: {bid: {}},
    historyList: [],
  };
  loading = true;
  gradeList: any[] = [];

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.pairingRecord
        .getPairingRecordDetail({id: this.dataId})
        .then((res) => {
          this.detail = res;
          this.loading = false;
          console.log(this.loading)
        });
    }
    this.fetchEnumList();
  }

  fetchEnumList(): void {
    api.enumService.getValues({typeName: "StudentGrade"}).then((res: any) => {
      this.gradeList = res;
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
