


































































































import {Component, Prop, Ref, Vue, Watch,} from "vue-property-decorator";

import api from "@/api";
import {CmsAnchorDto, SelectListItem, StudentBidType, StudentDonorDto, StudentDto} from "@/api/appService";
import {ElForm} from "element-ui/types/form";

@Component({
  name: "Edit",
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  pageLoading = true;
  defaultData: any = {
    id: undefined,
    studentIds: [],
    amount: undefined,
    remark: undefined,
    startYear: undefined,
    startMonth: undefined,
    endYear: undefined,
    endMonth: undefined,
    isPublish: false,
    isCreatePayOrder: false,
    startDate: undefined,
    endDate: undefined
  };

  studentList: StudentDto[] = [];

  yesOrNo: any[] = [{text: '是', value: true},
    {text: '否', value: false}]

  show = false;
  form: any = {...this.defaultData};

  @Watch("show")
  async onShowChange(value: boolean) {
    this.pageLoading = true;
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });
      //加载必要数据
      let studentResult = await api.student.getAll({maxResultCount: 65535});
      this.studentList = studentResult.items || [];

      this.form = {...this.defaultData};
      this.pageLoading = false;
      setTimeout(() => {
        loading.close();
      }, 200);
    } else {
      this.form = {...this.defaultData};
      this.pageLoading = false;
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        let startDateArray = this.form.startDate.split('-');
        let endDateArray = this.form.endDate.split('-');
        this.form.startYear = Number(startDateArray[0]);
        this.form.startMonth = Number(startDateArray[1]);
        this.form.endYear = Number(endDateArray[0]);
        this.form.endMonth = Number(endDateArray[1]);

        if (this.form.isPublish == false) {
          //只有在IsPublish为true时才可用
          this.form.createPayOrder = false;
        }
        console.log('form',this.form)

        await api.studentAidBid.batchCreate({body: this.form});
        this.show = false;
        this.$message.success("操作成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    studentIds: [
      {
        required: true,
        message: "请选择学生",
        trigger: "blur",
      },
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始年月",
        trigger: "change",
      },
    ],
    endDate: [
      {
        required: true,
        message: "请选择截止年月",
        trigger: "change",
      },
    ],
    amount: [
      {
        required: true,
        message: "请填写捐赠金额",
        trigger: "change",
      },
    ],
  };
}
