



































































































































































import {Component, Prop, Ref, Vue, Watch,} from "vue-property-decorator";

import api from "@/api";
import {SelectListItem, StudentBidType, StudentDonorDto, StudentDto} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import DonorEdit from "@/components/Donor/DonorEdit.vue";

@Component({
  name: "Edit",
  components: {DonorEdit},
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: number;

  defaultData: any = {
      studentId: undefined,
      amount: undefined,
      remark: undefined,
      startYear: undefined,
      startMonth: undefined,
      endYear: undefined,
      endMonth: undefined,
      grantTime: undefined,
      pairingTime: undefined,
      bidType: StudentBidType.OneToOne,
      donorWithAmountList: [],
      startDate:undefined,
      endDate:undefined
  };
  addDonorId = "";
  studentList: StudentDto[] = [];
  donorList: StudentDonorDto[] = [];

  bidTypeList: SelectListItem[] = [{text: '一对一', value: StudentBidType.OneToOne},
    {text: '多对一', value: StudentBidType.ManyForOne}]
  activeName = "first";

  show = false;
  form: any = {...this.defaultData};

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });
      //加载必要数据
      let studentResult = await api.student.getAll({maxResultCount: 65535});
      this.studentList = studentResult.items || [];
      let donorResult = await api.donor.getAll({isActive: true, maxResultCount: 65535});
      this.donorList = donorResult.items || [];

      this.form = {...this.defaultData};
      setTimeout(() => {
        loading.close();
      }, 200);

    } else {
      this.form = {...this.defaultData};
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    if (!(this.form.donorWithAmountList && this.form.donorWithAmountList.length > 0)) {
      this.$message.warning("请添加至少一个捐助人");
      return false;
    }

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        let startDateArray = this.form.startDate.split('-');
        let endDateArray = this.form.endDate.split('-');
        this.form.startYear = Number(startDateArray[0]);
        this.form.startMonth = Number(startDateArray[1]);
        this.form.endYear = Number(endDateArray[0]);
        this.form.endMonth = Number(endDateArray[1]);
        let amount = 0;
        this.form.donorWithAmountList.forEach((s: any) => amount += Number(s.amount))
        this.form.amount = amount;
        console.log(this.form)

        await api.pairingRecord.create({body: this.form});
        this.show = false;
        this.$message.success("新增成功");
        this.$emit("onSave");
      }
    });
  }

  // 添加一行
  private addRow(donorId: any) {
    if(!donorId) return
    if (this.form.bidType === StudentBidType.ManyForOne) {
      let obj = {
        /**  */
        donorId: donorId,

        /**  */
        amount: undefined
      };
      this.form.donorWithAmountList = [...this.form.donorWithAmountList!, obj];
      return;
    }

    if (this.form.bidType === StudentBidType.OneToOne && this.form.donorWithAmountList.length === 0) {
      let obj = {
        /**  */
        donorId: donorId,

        /**  */
        amount: undefined
      };
      this.form.donorWithAmountList = [...this.form.donorWithAmountList!, obj];
    } else {
      this.$message.error("捐助类型如选择一对一，则只可添加一条捐助人，请删除后重新添加");
      return
    }


  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.donorWithAmountList!.splice(index, 1);
  }

  cancel() {
    this.show = false;
  }

  async handleGetDonorId(donorId: number) {
    if (donorId && donorId > 0) {
      let donorResult = await api.donor.getAll({isActive: true, maxResultCount: 65535});
      this.donorList = donorResult.items || [];
      this.addRow(donorId)
    }
  }

  handleAddDonor() {
    (this.$refs.editDonor as any).innerVisible = true;
  }

  linkRule = {
    studentId: [
      {
        required: true,
        message: "请选择学生",
        trigger: "blur",
      },
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始年月",
        trigger: "change",
      },
    ],
    endDate: [
      {
        required: true,
        message: "请选择截止年月",
        trigger: "change",
      },
    ],
    grantTime: [
      {
        required: false,
        message: "请选择发放日期",
        trigger: "change",
      },
    ],
    pairingTime:[
      {
        required: true,
        message: "请选择结对日期",
        trigger: "change",
      },
    ],
    donorId: [
      {
        required: true,
        message: "请选择捐助人",
        trigger: "change",
      },
    ],
    amount: [
      {
        required: true,
        message: "请填写捐赠金额",
        trigger: "change",
      },
    ],
  };
}
