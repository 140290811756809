
















































































import { Vue, Component, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {
  SchoolContactDto,
  SchoolContactDtoPagedResultDto,
  SchoolDto,
  SchoolDtoPagedResultDto
} from "@/api/appService";
import IndexWithoutTown from "@/components/ProvinceCityArea/indexWithoutTown.vue";

export interface QueryForm {
  /**  */
  schoolName?: string;
  provinceId?: number;
  /**  */
  cityId?: number;
  /**  */
  areaId?: number;
  schoolId?: number;
}

@Component({
  name: "SchoolList",
  components: {
    PagedTableView,
    IndexWithoutTown
  }
})
export default class SchoolList extends Vue {
  schoolNameInSearch = "";
  queryForm: QueryForm = {};
  schoolList = [];
  schoolDetailDrawer = false;
  schoolDetailInfo: SchoolDto = {};
  schoolContacts: SchoolContactDto[] = [];
  detailId = 0;
  isResetSelector = false;

  @Watch("schoolNameInSearch")
  setQueryFormSchoolName(newVal: string) {
    this.$set(this.queryForm, "schoolName", newVal);
  }

  created() {
    if (this.$route.name === "school" && this.$route.params.id) {
      this.detailId = parseInt(this.$route.params.id);
    }
    this.fetchData();
  }

  fetchData(params?: any): Promise<SchoolDtoPagedResultDto> {
    return api.school.getAll(params);
  }

  closeSchoolDetailDrawer() {
    this.schoolDetailDrawer = false;
  }

  goToAddSchoolInfoPage() {
    this.$router.push({
      name: "addSchool",
      params: {}
    });
  }

  deleteCurrentSchool(row: SchoolDto) {
    this.$confirm("你确定删除吗?", "提示").then(() => {
      api.school
        .delete({ id: Number(row.id) })
        .then(() => {
          this.$message.success("删除成功");
        })
        .catch(() => {
          this.$message.error("删除失败");
        });
    })

  }

  getSchoolContacts(row: SchoolDto): Promise<SchoolContactDtoPagedResultDto> {
    return api.school.getAllContacts({ schoolId: Number(row.schoolNo) });
  }

  //跳转到联系人管理页面
  jumpToContactManagementPage(row: SchoolDto) {
    console.log(row.id, "id");
    this.$router.push({
      name: "schoolContact",
      params: { schoolId: row.id!.toString() }
    });
  }

  //跳转到编辑学校的页面
  editCurrentSchool(row: SchoolDto) {
    this.$router.push({
      name: "editSchool",
      params: {
        id: row.id!.toString()
      }
    });
    this.detailId = row.id!;
  }

  //跳转到查看学校详情页面
  jumpDetail(index: number, row: SchoolDto) {
    this.$router.push({
      name: "schoolDetail",
      params: {
        id: row.id!.toString()
      }
    });
    this.detailId = row.id!;
  }

  getChosenProvinceID(id: number) {
    this.$set(this.queryForm, "provinceId", id);
  }

  getChosenCityID(id: number) {
    this.$set(this.queryForm, "cityId", id);
  }

  getChosenAreaID(id: number) {
    this.$set(this.queryForm, "areaId", id);
  }

  finishReset(val: boolean) {
    this.isResetSelector = val;
    console.log("isResetSelector", this.isResetSelector);
  }
}
