





















































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  BidDto,
  PairingRecordDetailDto,
  PairingRecordDetailHistoryDto,
  StudentBidOrderDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";

@Component({
  name: "cmsContentDetail",
  components: {},
})
export default class cmsContentDetail extends Vue {
  dataId?: number;
  detail: StudentBidOrderDto = {};
  historyList: PairingRecordDetailHistoryDto[] = [];
  loading = true;
  gradeList: any[] = [];

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.detail = await api.studentBidOrder.get({ id: this.dataId });
      this.historyList = await api.donor.getDonorAidHistory({
        donorId: this.detail.studentDonor?.id,
      });
      this.loading = false;
    }
    this.fetchEnumList();
  }
  fetchEnumList(): void {
    api.enumService.getValues({ typeName: "StudentGrade" }).then((res: any) => {
      this.gradeList = res;
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
