
































































































































































import {Component, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import {SelectListItem, DonorType, StudentDonateRecordDto,} from "@/api/appService";
import ExportButton from "@/components/ExportButton/index.vue";
import StudentAidGrantRecordList from "@/views/studentAid/report/components/studentAidGrantRecordList.vue"

@Component({
  name: "donationIncomeList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
    StudentAidGrantRecordList
  },
})
export default class DonationIncomeList extends Vue {
  queryForm: any = {
    studentName: undefined,
    studentNo: undefined,
    donorName: undefined,
    paySuccessStartTime: undefined,
    paySuccessEndTime: undefined,
    transactionId: undefined,
    donorContactPhone: undefined,
    donorType: undefined,
    donorContactName: undefined
  };
  showTotalMoney = 1;
  totalMoney = 0;
  aidBidId = 0;
  donorTypeList: SelectListItem[] = [
    {value: DonorType.Personal, text: '个人'},
    {value: DonorType.Enterprise, text: '企业'}
  ];

  fetchData(params: any) {
    this.getTotalMoney();
    return api.studentAidReport.getAllDonateRecords(params);
  }

  // 合计金额
  getTotalMoney() {
    api.studentAidReport
      .getTotalAmount(this.queryForm)
      .then((res) => {
        this.totalMoney = res;
      });
  }

  handleGrantRecord(index: number, row: StudentDonateRecordDto) {
    this.aidBidId = Number(row.bid?.id);
    (this.$refs.AidGrantList as any).show = true;
  }
}
