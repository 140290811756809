





















































































import { Vue, Component } from "vue-property-decorator";
import { AreaDto, CustomFormType, SchoolContactDto, SchoolDto, StudentDto } from "@/api/appService";
import api from "@/api/index";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";
import IndexWithoutTown from "@/components/ProvinceCityArea/indexWithoutTown.vue";

@Component({
  name: "SchoolDetail",
  components: { CustomFormDetailView, IndexWithoutTown }
})
export default class SchoolDetail extends Vue {
  currentSchoolID = 0;
  schoolContacts?: SchoolContactDto[] = [];
  schoolDetailInfo: SchoolDto = {};
  labelStyle = {
    fontSize: "16px"
  };
  activeTab = "profile";
  loadingProfile = true;
  detail: SchoolDto = {};
  loading = true;
  provinceCityArea = "";

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async created() {
    if (this.$route.params.id) {
      this.currentSchoolID = Number(this.$route.params.id);
      const schoolDtoPromise = api.school.get({ id: this.currentSchoolID });
      const schoolContactDtoPromise = api.school.getAllContacts({
        schoolId: this.currentSchoolID
      });
      const [schoolDtoPromiseResolve, schoolContactDtoPromiseResolve] = await Promise.all([schoolDtoPromise, schoolContactDtoPromise]);
      this.schoolDetailInfo = schoolDtoPromiseResolve;
      this.schoolContacts = schoolContactDtoPromiseResolve.items;
      await this.getProvinceCityArea()
      this.loading = false;
    }
  }

  async getProvinceCityArea(){
    const { provinceName } = await this.getProvinceName(this.schoolDetailInfo.provinceId!);
    const { cityName } = await this.getCityName(this.schoolDetailInfo.cityId!);
    const areaRes = await this.getAreaName(this.schoolDetailInfo.areaId);
    const areaName = (areaRes as AreaDto).areaName ?? "";
    this.provinceCityArea = provinceName! + cityName + areaName;
  }

  getProvinceName(id: number) {
    return api.province.get({ id: id });
  }

  getCityName(id: number) {
    return api.city.get({ id: id });
  }

  getAreaName(id?: number) {
    if (id) {
      return api.area.get({ id: id });
    } else {
      return new Promise<string>((resolve) => {
        resolve("");
      });
    }

  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  back() {
    this.$router.go(-1);
  }
}
