

































































import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
import Base64 from "@/utils/Base64";
import api from "@/api";
import {
  ProvinceDto,
  CityDto,
  AreaDto,
  TownDto,
  AttachmentHostType,
} from "@/api/appService";

export interface ProvinceCityAreaTownDto {
  /**  */
  provinceId?: undefined;

  /**  */
  cityId?: undefined;

  /**  */
  areaId?: undefined;

  townId?: undefined;
}

@Component({
  name: "ProvinceCityArea",
})
export default class ProvinceCityArea extends Vue {
  // @Model("change", { type: Array, default: [] }) readonly fileIds!: number[];

  @Prop({required: false})
  province!: undefined;

  @Prop({required: false})
  city!: undefined;

  @Prop({required: false})
  area!: undefined;

  @Prop({required: false})
  town!: undefined;

  @Prop({required: false})
  needTown!: true;

  @Prop() dataId?: number;

  form?: ProvinceCityAreaTownDto = {};

  provinceList?: ProvinceDto[] = [];
  cityList?: CityDto[] = [];
  areaList?: AreaDto[] = [];
  townList?: TownDto[] = [];

  created() {
    this.init();
  }
  @Watch("province")
  changeProvince(val: number | undefined) {
    if (!val) {
      this.form!.provinceId = undefined;
      this.form!.cityId = undefined;
      this.form!.areaId = undefined;
      this.form!.townId = undefined;
      this.provinceList = [];
      this.cityList = [];
      this.areaList = [];
      this.townList = [];
      api.province.getAllList().then((res: any) => {
        this.provinceList = res!;
      });
    }
  }

  @Watch("dataId")
  init() {
    this.form!.provinceId = this.province == 0 ? undefined : this.province;
    this.form!.cityId = this.city == 0 ? undefined : this.city;
    this.form!.areaId = this.area == 0 ? undefined : this.area;
    this.form!.townId = this.town == 0 ? undefined : this.town;
    this.updateData();
    if (!this.provinceList || this.provinceList.length === 0) {
      api.province.getAllList().then((res: any) => {
        this.provinceList = res!;
      });
    }

    // if (this.dataId ?? 0 > 0) {
    if (this.form!.provinceId) {
      this.showCityList(true);
    }

    if (this.form!.cityId) {
      this.showAreaList(true);
    }

    if (this.form!.areaId) {
      this.showTownList(true);
    }
    // }
  }

  showCityList(isInit: boolean) {
    if (!isInit) {
      this.$set(this.form!, "cityId", "");
      this.$set(this.form!, "areaId", "");
      this.$set(this.form!, "townId", "");
      //
      this.updateData();
    }

    api.city
      .getAllList({provinceId: this.form!.provinceId})
      .then((res: any) => {
        this.cityList = res!;
      });
  }

  showAreaList(isInit: boolean) {
    if (!isInit) {
      this.$set(this.form!, "areaId", "");
      this.$set(this.form!, "townId", "");
      //
      this.updateData();
    }

    api.area.getAllList({cityId: this.form!.cityId}).then((res: any) => {
      this.areaList = res!;
    });
  }

  showTownList(isInit: boolean) {
    if (!isInit) {
      this.$set(this.form!, "townId", "");
      //
      this.updateData();
    }

    api.town.getAllList({areaId: this.form!.areaId}).then((res: any) => {
      this.townList = res!;
    });
  }

  setTownValue(isInit: boolean) {
    if (!isInit) {
      this.updateData();
    }
    api.town.getAllList({areaId: this.form!.areaId}).then((res: any) => {
      this.townList = res!;
    });
  }

  updateData() {
    this.$emit("update:province", this.form!.provinceId);
    this.$emit("update:city", this.form!.cityId);
    this.$emit("update:area", this.form!.areaId);
    this.$emit("update:town", this.form!.townId);
  }
}
