




































































import PagedTableView from "@/components/PagedTableView/index.vue";
import {Vue, Component, Watch} from "vue-property-decorator";
import api from "@/api/index";
import {SchoolContactDto, SchoolContactDtoPagedResultDto, StudentDonorDto} from "@/api/appService";
import ImportExcel from "@/components/ImportExcel/index.vue";

export function getSchoolNameBySchoolId(schoolId: number) {
  //调用后需要解构出学校名
  return api.school.get({id: schoolId});
}

@Component({
  name: "ContactList",
  components: {PagedTableView, ImportExcel},
  filters: {
    stateFormat(isActive: boolean) {
      return isActive ? "已激活" : "已禁用";
    }
  }
})
export default class ContactList extends Vue {
  queryForm = {
    contactName: "",
    schoolId: 0,
    contactPhone: ""
  };
  isDisabled = false;
  currentContactId = 0;

  fetchData(params?: any): Promise<SchoolContactDtoPagedResultDto> {
    return api.school.getAllContacts(params);
  }

  created() {
    this.$set(this.queryForm, "schoolId", Number(this.$route.params.schoolId));
    console.log(this.queryForm);
  }

  addContactInfo() {
    this.$router.push({
      name: "contactAdd",
      params: {schoolId: this.$route.params.schoolId}
    });
  }

  // 删除
  handleDelete(index: number, row: SchoolContactDto) {
    this.$confirm("注意：删除当前联系人后，反馈记录仍然保留，联系人账号信息会被删除，请谨慎操作~", "提示").then(() => {
      api.school
        .deleteContract({
          id: row.id
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        });
    });
  }

  handleEdit(index: number, row: SchoolContactDto) {
    this.$router.push({
      name: "contactEdit",
      params: {
        contactId: row.id!.toString()
      }
    });
  }

  handleDetail(index: number, row: SchoolContactDto) {
    this.$router.push({
      name: "contactDetail",
      params: {
        contactId: row.id!.toString()
        // schoolName: schoolName!
      }
    });
  }

  handleFeedbackRecord(index: number, row: SchoolContactDto) {
    this.$router.push({
      name: "studentFeedbackRecord",
      params: {
        userExtendId: row.userExtend!.creatorUserId!.toString(),
        schoolId: row.schoolId!.toString()
      }
    });
  }

  handleDisabled(index: number, row: SchoolContactDto) {
    api.school.disableContact({
      body: {id: row.id}
    }).then(() => {
      this.$message.success("修改成功");
    });
  }

  handleActive(index: number, row: SchoolContactDto) {
    console.log(row);
    api.school.activeContact({
      body: {
        id: row.id
      }
    }).then(() => {
      this.$message.success("修改成功");
    });
  }

  stateFormat(row: StudentDonorDto) {
    if (row.isActive) {
      return "已激活";
    } else {
      return "已禁用";
    }
  }
}
