




















































































































import {Component, Vue} from "vue-property-decorator";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {CustomFormType, StudentDto, PairingRecordDto, PairingRecordDtoPagedResultDto} from "@/api/appService";
import api from "@/api";

@Component({
  name: "VolunteerDetail",
  components: {
    CustomFormDetailView,
    AttachmentsView,
  },
})
export default class StudentDetail extends Vue {
  activeTab = "profile";
  detail: StudentDto = {};
  studentId = 0;
  loadingProfile = true;
  gradeList: any[] = [];
  pairingRecordList: PairingRecordDto[] = [];

  get hostType() {
    return CustomFormType.Student;
  }

  created() {
    if (this.$route.params.id) {
      this.studentId = Number(this.$route.params.id);
    }
    this.fetchEnumList();
    this.fetchParingRecords();
  }

  fetchEnumList(): void {
    api.enumService
      .getValues({typeName: "StudentGrade"})
      .then((res: any) => {
        this.gradeList = res;
      });
  }

  getStudentInfo() {
    return new Promise<StudentDto>((resolve, reject) => {
      if (!this.loadingProfile) {
        resolve(this.detail);
      } else {
        api.student.get({id: this.studentId}).then((res) => {
          this.detail = res;
          this.loadingProfile = false;
          resolve(res);
        });
      }
    });
  }

  fetchParingRecords() {
    api.pairingRecord.getAllOfBidCompleted({
      studentId: this.studentId,
      maxResultCount: 1000
    }).then((res: PairingRecordDtoPagedResultDto) => {
      this.pairingRecordList = res.items!;
    })
  }

}
