

























import {Component, Vue} from "vue-property-decorator";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import {CustomFormType, StudentCreateOrUpdateDto, StudentDto, VolunteerCreateOrUpdateDto} from "@/api/appService";
import api from "@/api";

@Component({name: "EditStudent", components: {CustomFormEdit}})
export default class EditStudent extends Vue {
  dataId = 0;
  btnDisabled = false;
  submitState = ""; // empty | submitting
  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }

  get hostType() {
    return CustomFormType.Student;
  }

  defaultData: StudentDto = {};

  get getData(): Promise<StudentDto> {
    if (this.dataId > 0) return api.student.get({id: this.dataId});
    else return new Promise<StudentDto>(resolve => this.defaultData);
  }

  handleSave(): void {
    this.btnDisabled = true;
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        const data = (
          this.$refs.customForm as any
        ).getRequestData() as StudentCreateOrUpdateDto;
        let fn;
        this.submitState = "submitting";
        if (this.dataId) fn = api.student.update;
        else fn = api.student.create;
        data.extensionData='';
        fn({body: data}).then((res) => {
          this.$message.success("更新成功");
          this.$router.push({
            name: "studentList",
          });
          this.submitState = "";
        });
      } else {
        this.$message.error("请检查表单");
        this.btnDisabled = false;
      }
    });
  }
}
