








































import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  CmsAnchorDto,
  CmsAnchorCreateOrUpdateDto,
  StudentDtoPagedResultDto,
  StudentDto,
  ChangeStudentInput
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditAnchor",
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: ChangeStudentInput = {
    recordId: 0,
    studentId: undefined,
    reason: ''
  };

  studentList:StudentDto[]=[];

  show = false;
  form: ChangeStudentInput = { ...this.defaultData };

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      if (this.dataId) {
        this.form.recordId=this.dataId;
        await api.student
          .getAll({maxResultCount:65535})
          .then((res: StudentDtoPagedResultDto) => {
            this.studentList = res.items!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = { ...this.defaultData };
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.pairingRecord.changeStudent({body: {recordId:this.form.recordId,studentId:this.form.studentId,reason:this.form.reason}});
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    studentId: [
      {
        required: true,
        message: "请选择学生",
        trigger: "blur",
      },
    ],
    reason: [
      {
        required: true,
        message: "请输入变更原因",
        trigger: "change",
      },
    ],
  };
}
