







































import {
  Component,
  Vue,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  StudentDonorCreateOrUpdateDto, StudentDonorType, SelectListItem, DataDictionaryCreateOrUpdateDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";

@Component({
  name: "EditData",
})
export default class EditData extends Vue {
  @Ref() readonly dataForm!: ElForm;

  defaultData: StudentDonorCreateOrUpdateDto = {
    name: "",
    type: "" as StudentDonorType | undefined,
    contactName: "",
    contactPhone: "",
    contactEmailAddress: "",
  };

  formData: StudentDonorCreateOrUpdateDto = { ...this.defaultData };

  innerVisible = false;

  studentDonorTypeList: SelectListItem[] = [];

  get title() {
    return "新建捐助人";
  }

  @Watch("innerVisible")
  async onShowChange(value: boolean) {
    this.formData = { ...this.defaultData };
    this.studentDonorTypeList = await api.enumService.getValues({typeName: "StudentDonorType"});
  }

  async save() {
    console.log(this.form);
    (this.$refs.dataForm as any).validate((isValid: boolean) => {
      if (!isValid) {
        return;
      }
      api.donor.create({body: this.formData}).then((res) => {
        this.$message.success("捐赠人新增成功");
        this.innerVisible = false;
        this.$emit("donorId", res.id);
      }).catch(() => {
        this.$message.error("提交失败");
      });
    })
  }

  cancel() {
    this.innerVisible = false;
  }

  checkPhone(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("手机号不能为空"));
    } else {
      const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    }
  }

  rules = {
    name: [{required: true, message: "请输入捐助方名称", trigger: "blur"}],
    type: [{required: true, message: "请选择捐助方类型", trigger: "change"}],
    contactName: [{required: true, message: "请输入联系人名称", trigger: "blur"}],
    contactPhone: [{required: true, message: "请输入联系人手机号", trigger: "blur"}, {
      validator: this.checkPhone,
      trigger: ["blur", "change"]
    }],
    contactEmailAddress: [{required: true, message: "请输入邮箱", trigger: "blur"}, {
      type: "email",
      message: "请输入正确的邮箱地址",
      trigger: ["blur", "change"]
    }]
  };
}
