































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import {StudentDto, StudentDtoPagedResultDto, CustomFormType} from "@/api/appService";
import api from "@/api";
import ImportExcel from "@/components/ImportExcel/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/indexForSearch.vue";
import BatchDialog from "@/views/studentAid/students/Components/BatchDialog.vue";
import PublishBid from "@/views/studentAid/bid/edit.vue";

@Component({
  name: "StudentList",
  components: {
    BatchDialog,
    PagedTableView,
    AbSelect,
    ImportExcel,
    ExportButton,
    ProvinceCityArea,
    PublishBid
  },
})
export default class StudentList extends Vue {
  queryForm: any = {
    studentNo: "",
    studentName: "",
    counterpart:"",
    studentGrade: "",
    schoolName: "",
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined,
    sex: "",
    studentStatus: "",
    bidStatus: "",
  };

  sexList: any[] = [];
  gradeList: any[] = [];
  studentStatusList: any[] = [];
  bidStatusList: any[] = [];
  customFormId = '';
  selectionList: any[] = [];

  created(): void {
    this.fetchEnumList();
    this.getCustomFormId();
  }


  fetchEnumList(): void {
    api.enumService
      .getValues({typeName: "Sex"})
      .then((res: any) => {
        this.sexList = res;
      });
    api.enumService
      .getValues({typeName: "StudentGrade"})
      .then((res: any) => {
        this.gradeList = res;
      });
    api.enumService
      .getValues({typeName: "StudentStatus"})
      .then((res: any) => {
        this.studentStatusList = res;
      });
    api.enumService
      .getValues({typeName: "StudentAidBidStatus"})
      .then((res: any) => {
        this.bidStatusList = res;
      });
  }

  getCustomFormId(): void {
    api.customFormService.getCustomFormId({hostType: CustomFormType.Student}).then((res: string) => {
      this.customFormId = res;
    })
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows;
  }

  fetchData(params: any): Promise<StudentDtoPagedResultDto> {
    return api.student.getAll(params);
  }

  handleCreate(): void {
    if (!this.customFormId) {
      if (this.hasPermission("Pages.CustomFormSetting")) {
        this.$confirm("还没有设计学生的自定义表单，现在去设计？", "提示", {
          confirmButtonText: '立即去设计',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.$router.push({
            name: "customFormDesign",
            query: {
              hostId: '',
              hostType: CustomFormType.Student,
            },
          });
        });
      } else {
        this.$message.warning("请联系机构管理员设计表单");
      }
    } else {
      this.$router.push({
        name: "studentCreate"
      })
    }
  }

  handleConfigureForm(){
    this.$router.push({
      name: "customFormDesign",
      query: {
        hostId: '',
        hostType: CustomFormType.Student,
      },
    });
  }

  handleSchoolDetail(id: number): void {
    this.$router.push({
      name: "schoolDetail",
      params: {
        id: id!.toString()
      }
    });
  }

  handleDetail(row: StudentDto): void {
    this.$router.push({
      name: "studentDetail",
      params: {
        id: row.id!.toString(),
      }
    });
  }

  handleEdit(row: StudentDto): void {
    this.$router.push({
      name: "studentEdit",
      params: {
        id: row.id!.toString(),
      }
    })
  }

  handleDelete(row: StudentDto): void {
    this.$confirm("注意：删除当前学生后，关联的反馈记录、助学标的、结对记录都会删除，请谨慎操作~", "提示", {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      api.student.delete({id: row.id}).then(() => {
        this.$message.success("删除成功");
      });
    });
  }

  handlePublishBid(row: StudentDto): void {
    let dialog = (this.$refs.publishBid as any);
    dialog.show = true;
    dialog.defaultData.studentId = row.id;
  }

  handleFeedbackRecord(row: StudentDto): void {
    console.log(row)
    this.$router.push({
      name: "studentFeedbackList",
      params: {
        schoolId: row.schoolId!.toString(),
        studentId: row.id!.toString()
      }
    })
  }

  handleBatchUpdate(): void {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请先选择学生!",
      });
      return;
    }
    let form = this.$refs.batchDialog as any;
    form.show = true;
    form.selectedList = this.selectionList;
    form.reset();
  }
}
