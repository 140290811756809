



























































































import {Component, Ref, Vue} from "vue-property-decorator";
import {BidDto, PairingRecordDetailDto} from "@/api/appService";
import api from "@/api";
import moment from "moment";

@Component({
  name: "cmsContentDetail",
  components: {},
})
export default class cmsContentDetail extends Vue {
  dataId?: number;
  detail: BidDto = {
  };
  loading = true;
  gradeList: any[] = [];

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.studentAidBid.get({id: this.dataId}).then(res => {
        this.detail = res;
        this.loading = false;
      });
    }
    this.fetchEnumList();
  }

  fetchEnumList(): void {
    api.enumService
      .getValues({typeName: "StudentGrade"})
      .then((res: any) => {
        this.gradeList = res;
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
