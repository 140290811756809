

























































































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";
import {CmsAnchorDto, PairingRecordPagedResultRequestDto,} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ChangeStudent from "@/views/studentAid/pairingRecords/changeStudent.vue";
import EditLink from "@/views/studentAid/pairingRecords/edit.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";
import BatchGrant from "@/views/studentAid/bid/batchGrant.vue";
import moment from "moment";

@Component({
  name: "AnchorList",
  components: {
    ChangeStudent,
    PagedTableView,
    ExportButton,
    ImportExcel,
    EditLink, BatchGrant
  },
})
export default class AnchorList extends Vue {
  currentRowData = {};
  queryForm = {
    studentName: "",
    studentNo: "",
    schoolName: "",
    donorName: "",
    startDate: undefined,
    endDate: undefined,
    counterpart: ""
  } as PairingRecordPagedResultRequestDto;
  dataIds = [];
  editLinkId = 0;
  gradeList: any[] = [];
  selectionList: any[] = [];
  isBatch = false
  dialogVisible = false
  form = {
    id: 0,
    grantTime: undefined,
    grantedAmount: undefined,
    grantRemark: "",
    amount: 0.00,
    grantedTotalAmount: undefined,
    availableMoney: undefined
  }

  created(): void {
    this.fetchEnumList();
  }


  fetchEnumList(): void {
    api.enumService.getValues({typeName: "StudentGrade"}).then((res: any) => {
      this.gradeList = res;
    });
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.pairingRecord.getAllOfBidCompleted(params);
  }

  // 新建
  handleCreate() {
    this.editLinkId = 0;
    (this.$refs.editForm as any).show = true;
  }

  //批量操作弹窗显示
  handleBatchGrant() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message.error("请选择至少一条数据!");
      return;
    }

    let isGranted = false;
    this.selectionList.forEach((item) => {
      if (item.bid.status !== 'HasParing') {
        isGranted = true;
      }
    })
    if (isGranted) {
      this.$message.error("选择的数据中存在已发放的数据！");
      return;
    }

    this.dialogVisible = true;
    this.isBatch = true;
  }

  handlePublish() {
    if (this.isBatch) {
      this.handleBatchPublish()
    } else {
      this.handleSinglePublish()
    }
  }

// 单个发放
  handleSinglePublish() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (Number(this.form.grantedAmount) <= 0) {
          this.$message.error("发放金额应大于0!");
          return false;
        }

        api.studentAidBid.batchGrant({
          body: {
            ids: [this.form.id],
            grantTime: this.form.grantTime,
            grantedAmount: this.form.grantedAmount,
            grantRemark: this.form.grantRemark,
          }
        }).then(() => {
          this.dialogVisible = false
          this.$message.success("发放成功");
          this
        });
      } else {
        this.$message.error("请检查表单")
      }
    })
  }

  //批量发放
  async handleBatchPublish() {
    this.dataIds = [];
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message.error("请选择至少一条数据!");
      return false;
    }

    if (!this.form.grantTime) {
      this.$message.error("请选择发放时间!");
      return false;
    }
    if (Number(this.form.grantedAmount) <= 0) {
      this.$message.error("发放金额应大于0!");
      return false;
    }


    let ids: any = [];
    this.selectionList.forEach((item: any) => {
      ids.push(item.id);
      console.log("item=" + item.id)
    });
    console.log("ids=" + ids)
    this.dataIds = ids;
    await api.studentAidBid
      .batchGrant({
        body: {
          ids: ids,
          grantTime: this.form.grantTime,
          grantedAmount: this.form.grantedAmount,
          grantRemark: this.form.grantRemark
        },
      })
      .then(() => {
        this.dialogVisible = false
        this.$message.success("操作成功!");
      });

  }

  // 跳转详情页
  handleDetail(item: any) {
    this.$router.push({
      name: "pairingRecordsDetail",
      params: {id: item.id!.toString()},
    });
  }

  // 获取已选择数据
  getSelectionList(rows: any[]) {
    this.selectionList = rows;
  }

  // 变更学生
  handleChangeStudent(index: number, row: any) {
    this.editLinkId = row.id!;
    (this.$refs.changeStudentForm as any).show = true;

    console.warn("%cu must reWrite this method", "color:#0048BA;");
  }

  // 删除
  async handleDelete(index: number, row: CmsAnchorDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.cmsAnchor
        .delete({
          id: row.id,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleGrant(item: any) {
    this.dialogVisible = true
    this.form.id = item.id
    this.form.amount = item.amount
    this.form.grantedTotalAmount = item.grantedTotalAmount
    this.form.availableMoney = item.availableMoney
    this.form.grantTime = undefined
    this.form.grantedAmount = undefined
    this.form.grantRemark = ""
  }

// 取消发放
//   handleCancelPublish(item: any) {
//     this.$confirm("确定取消发放吗?", "提示").then(() => {
//       api.studentAidBid.cancelPublish({body: {id: item.bid.id}}).then((res) => {
//         this.$message.success("取消成功");
//       });
//     });
//   }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }

  linkRule = {
    grantTime: [{
      required: true,
      message: "请选择发放时间",
      trigger: "blur",
    }],
    grantedAmount: [{
      required: true,
      message: "请填写发放金额",
      trigger: "blur",
    }],
  };
}
