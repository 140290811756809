





































































import {Vue, Component, Watch} from "vue-property-decorator";
import api from "@/api/index";
import {SchoolCreateOrUpdateDto, SchoolDto} from "@/api/appService";
import IndexWithoutTown from "@/components/ProvinceCityArea/indexWithoutTown.vue";

@Component({
  name: "SchoolAdd",
  components: {IndexWithoutTown}
})
export default class SchoolAdd extends Vue {
  isLoading = false;
  isEdit = false;
  labelPosition = "top";
  formData: SchoolDto | undefined = {
    schoolName: "",
    provinceId: 0,
    cityId: 0,
    areaId: 0,
    address: "",
    remark: ""
  };
  rules = {
    schoolName: [{required: true, message: "请输入学校名称", trigger: "blur"}],
    address: [{required: true, message: "请输入详细地址", trigger: "blur"}],
    provinceId: [{required: true, message: "请选择省份/城市", trigger: "change"}]
  };


  async created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.formData = await this.getSchoolInfoById();
    }

  }

  getSchoolInfoById() {
    if (this.$route.params.id) {
      return api.school.get({
        id: Number(this.$route.params.id)
      });
    }

  }

  onSubmit(formName: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.$refs[formName]!.validate((valid: unknown) => {
      if (valid) {
        this.isLoading = true;
        if (!this.isEdit) {
          api.school.create({body: this.formData}).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();

          }).catch(() => {
            this.$message.error("提交失败");
            this.isLoading = false;
          });
        } else {
          api.school.update({body: this.formData}).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();

          }).catch(() => {
            this.$message.error("提交失败");
            this.isLoading = false;
          });
        }
      } else {
        this.$message.error("提交失败");
        this.isLoading = false;
        return false;
      }
    });
  }

  back() {
    this.$router.go(-1);
  }
}
