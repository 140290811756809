












































































































































































import {Component, Vue} from "vue-property-decorator";
import {AttachmentHostType, StudentStatus} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import Ueditor from "@/components/Ueditor/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  name: "BatchDialog",
  components: {
    MultipleUploadFile,
    Ueditor
  },
})
export default class BatchDialog extends Vue {

  form: any = {
    selectedAction: '',
    selectedStatus: '',
    grantTime: undefined,
    studentIds: [],
    amount: undefined,
    startYear: undefined,
    startMonth: undefined,
    endYear: undefined,
    endMonth: undefined,
    isPublish: false,
    isCreatePayOrder: false,
    startDate: undefined,
    endDate: undefined,
    title: '',
    grade: '',
    year: undefined,
    term: undefined,
    content: '',
    savedAttachments: []
  };

  show = false;
  yesOrNo: any[] = [{text: '是', value: true}, {text: '否', value: false}];
  actionList: any[] = [];
  selectedList: any[] = [];
  studentStatusList: any[] = [];
  gradeList: any[] = [];
  yearList: any[] = [];
  termList: any[] = [{text: '第一学期', value: 1}, {text: '第二学期', value: 2}];

  created(): void {
    this.initSelectOptions();
    this.fetchEnumList();
  }

  get hostTypeFile() {
    return AttachmentHostType.StudentFeedback;
  }

  reset() {
    if (this.$refs.dataForm)
      (this.$refs.dataForm as any).resetFields();
  }

  fetchEnumList(): void {
    let currentYear = moment().year();
    console.log(currentYear)
    for (let i = currentYear; i >= 2000; i--) {
      this.yearList.push({text: i, value: i});
    }
    api.enumService
      .getValues({typeName: "StudentStatus"})
      .then((res: any) => {
        this.studentStatusList = res;
      });
    api.enumService
      .getValues({typeName: "StudentGrade"})
      .then((res: any) => {
        this.gradeList = res;
      });
  }

  initSelectOptions(): void {
    if (this.hasPermission('Pages.StudentAid.Bid.Create')) {
      this.actionList.push({
        value: 'PublishBid',
        text: '批量发布标的'
      })
    }
    if (this.hasPermission('Pages.StudentAid.Bid.Grant')) {
      this.actionList.push({
        value: 'Grant',
        text: '批量发放'
      })
    }
    if (this.hasPermission('Pages.StudentAid.Student.FeedbackRecord.Create')) {
      this.actionList.push({
        value: 'CreateFeedback',
        text: '批量反馈'
      })
    }
    if (this.hasPermission('Pages.StudentAid.StudentInfo.Update')) {
      this.actionList.push({
        value: 'UpdateStudentStatus',
        text: '批量修改学习状态'
      })
    }
    if (this.hasPermission('Pages.StudentAid.StudentInfo.Delete')) {
      this.actionList.push({
        value: 'Delete',
        text: '批量删除'
      })
    }
  }

  confirm(): void {
    if (!this.form.selectedAction) {
      this.$message.error("请选择要批量操作的事项");
      return;
    }
    console.log(this.selectedList)
    this.form.studentIds = this.selectedList.map(item => {
      return Number(item.id)
    });
    let selectedBidIdList = this.selectedList.filter(item => item.bid).map(item => {
      return Number(item.bid.id);
    });
    console.log(selectedBidIdList)
    if (this.form.selectedAction === 'Delete') {
      this.$confirm("注意：删除学生后，关联的反馈记录、助学标的、结对记录都会删除，请谨慎操作~", "提示", {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        api.student.batchDelete({body: {studentId: this.form.studentIds}}).then(() => {
          this.$message.success("批量删除成功");
          this.show = false
        });
      });

    } else if (this.form.selectedAction === 'UpdateStudentStatus') {
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        if (valid) {
          this.$confirm("确认批量修改学生学习状态吗", "提示", {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            api.student.batchUpdateStatus({
              body: {
                studentId: this.form.studentIds,
                status: (this.form.selectedStatus as StudentStatus)
              }
            }).then(() => {
              this.$message.success("批量修改成功");
              this.show = false;
            });
          });
        }
      });
    } else if (this.form.selectedAction === 'Grant') {
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        if (valid) {
          api.studentAidBid.batchGrant({body: {grantTime: this.form.grantTime, ids: selectedBidIdList}}).then(() => {
            this.$message.success("批量发放成功");
            this.show = false;
          });
        }
      });
    } else if (this.form.selectedAction === 'PublishBid') {
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        if (valid) {
          let startDateArray = this.form.startDate.split('-');
          let endDateArray = this.form.endDate.split('-');
          this.form.startYear = Number(startDateArray[0]);
          this.form.startMonth = Number(startDateArray[1]);
          this.form.endYear = Number(endDateArray[0]);
          this.form.endMonth = Number(endDateArray[1]);

          if (!this.form.isPublish) {
            //只有在IsPublish为true时才可用
            this.form.createPayOrder = false;
          }
          console.log('form', this.form)

          api.studentAidBid.batchCreate({body: this.form}).then(() => {
            this.$message.success("批量发布标的成功");
            this.show = false;
          });
        }
      });
    } else if (this.form.selectedAction === 'CreateFeedback') {
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        if (valid) {
          api.studentFeedbackRecord.batchCreate({body: this.form}).then(() => {
            this.$message.success("批量反馈成功");
            this.show = false;
          });
        }
      });
    }
  }

  linkRule = {
    selectedAction: [
      {
        required: true,
        message: "请选择要批量操作的事项",
        trigger: "blur",
      },
    ],
    selectedStatus: [
      {
        required: true,
        message: "请选择学习状态",
        trigger: "blur",
      },
    ],
    grantTime: [
      {
        required: true,
        message: "请选择发放时间",
        trigger: "blur",
      },
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始年月",
        trigger: "blur",
      },
    ],
    endDate: [
      {
        required: true,
        message: "请选择截止年月",
        trigger: "blur",
      },
    ],
    amount: [
      {
        required: true,
        message: "请填写捐赠金额",
        trigger: "blur",
      },
    ],
    title: [
      {
        required: true,
        message: "请填写捐反馈标题",
        trigger: "blur",
      },
      {
        max: 50,
        message: "反馈标题长度不可超过50",
        trigger: "blur",
      },
    ],
    grade: [
      {
        required: true,
        message: "请选择对应年级",
        trigger: "change",
      },
    ],
    year: [
      {
        required: true,
        message: "请选择学年",
        trigger: "change",
      },
    ],
    term: [
      {
        required: true,
        message: "请选择学期",
        trigger: "change",
      },
    ],
    content: [
      {
        required: true,
        message: "请输入反馈详情",
        trigger: "blur",
      },
    ],
  };
}
