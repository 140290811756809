






























































































































































































import {Component, Prop, Ref, Vue, Watch,} from "vue-property-decorator";

import api from "@/api";
import {CmsAnchorDto, SelectListItem, StudentBidType, StudentDonorDto, StudentDto} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import DonorEdit from "@/components/Donor/DonorEdit.vue";

@Component({
  name: "Edit",
  components: {DonorEdit},
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: number;
  activeName = "first";
  pageLoading = true;
  defaultData: any = {
    id: undefined,
    studentId: undefined,
    amount: undefined,
    remark: undefined,
    startYear: undefined,
    startMonth: undefined,
    endYear: undefined,
    endMonth: undefined,
    grantTime: undefined,
    bidType: StudentBidType.OneToOne,
    lowestDonateAmount: undefined,
    isPublish: false,
    createPayOrder: false,
    donorWithAmountList: [],
    startDate: undefined,
    endDate: undefined
  };

  studentList: StudentDto[] = [];
  donorList: StudentDonorDto[] = [];
  addDonorId = '';

  bidTypeList: any[] = [{text: '一对一', value: StudentBidType.OneToOne}]

  yesOrNo: any[] = [{text: '是', value: true},
    {text: '否', value: false}]

  show = false;
  form: any = {...this.defaultData};

  @Watch("show")
  async onShowChange(value: boolean) {
    this.pageLoading = true;
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });
      //加载必要数据
      let studentResult = await api.student.getAll({maxResultCount: 65535});
      this.studentList = studentResult.items || [];
      let donorResult = await api.donor.getAll({isActive: true, maxResultCount: 65535});
      this.donorList = donorResult.items || [];

      if (this.dataId) {
        await api.studentAidBid
          .get({id: this.dataId})
          .then((res: any) => {
            //
            this.form.id = this.dataId;
            this.form.studentId = res.student.id;
            this.form.amount = res.amount;
            this.form.remark = res.remark;
            // this.form.startYear: undefined,
            // this.form.startMonth: undefined,
            // this.form.endYear: undefined,
            // this.form.endMonth: undefined,
            this.form.grantTime = res.grantTime;
            this.form.bidType = res.bidType;
            this.form.lowestDonateAmount = res.lowestDonateAmount;
            this.form.isPublish = false;
            this.form.createPayOrder = false;

            if (res && res.donorObject && res.donorObject.details && res.donorObject.details.length > 0) {
              this.form.donorWithAmountList = [];
              res.donorObject.details.forEach((s: any) => {
                this.form.donorWithAmountList.push({donorId: s.donorId, amount: s.amount});
              });
            }

            this.form.startDate = res.startDate;
            this.form.endDate = res.endDate;
            this.pageLoading = false;
            // this.form = res!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = {...this.defaultData};
        this.pageLoading = false;
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = {...this.defaultData};
      this.pageLoading = false;
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  handleAddDonor() {
    (this.$refs.editDonor as any).innerVisible = true;
  }

  setLowestDonateAmount() {
    this.form.lowestDonateAmount = this.form.amount;
  }

  async save() {
    // if (!(this.form.donorWithAmountList && this.form.donorWithAmountList.length > 0)) {
    //   this.$message.warning("请添加至少一个捐助人");
    //   return false;
    // }
    if (this.form.createPayOrder) {
      if (this.form.donorWithAmountList.length !== 1) {
        this.$message.error("如需要创建待付款订单，请添加一条捐助人,且只可添加一条");
        return
      }
    } else {
      if (this.form.donorWithAmountList.length > 0) {
        this.$message.error("如不需创建代付款订单则不可添加捐助人，请删除后重试");
        return
      }
    }

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        let startDateArray = this.form.startDate.split('-');
        let endDateArray = this.form.endDate.split('-');
        this.form.startYear = Number(startDateArray[0]);
        this.form.startMonth = Number(startDateArray[1]);
        this.form.endYear = Number(endDateArray[0]);
        this.form.endMonth = Number(endDateArray[1]);

        if (this.form.isPublish == false) {
          //只有在IsPublish为true时才可用
          this.form.createPayOrder = false;
        }
        // let amount = 0;
        // this.form.donorWithAmountList.forEach((s: any) => amount += Number(s.amount))
        // this.form.amount = amount;
        console.log(this.form)

        if (this.dataId) {
          await api.studentAidBid.update({body: this.form});
        } else {
          await api.studentAidBid.create({body: this.form});
        }
        this.show = false;
        this.$message.success("操作成功");
        this.$emit("onSave");
      }
    });
  }

  async handleGetDonorId(donorId: number) {
    if (donorId && donorId > 0) {
      let donorResult = await api.donor.getAll({isActive: true, maxResultCount: 65535});
      this.donorList = donorResult.items || [];
      this.addRow(donorId)
    }
  }

  // 添加一行
  private addRow(donorId: any) {
    if(!donorId) return
    if (this.form.donorWithAmountList.length === 0) {
      let obj = {
        /**  */
        donorId: donorId,

        /**  */
        amount: undefined
      };

      this.form.donorWithAmountList = [...this.form.donorWithAmountList!, obj];
    } else {
      this.$message.error("只可添加一条捐赠人");
      return
    }

  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.donorWithAmountList!.splice(index, 1);
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    studentId: [
      {
        required: true,
        message: "请选择学生",
        trigger: "blur",
      },
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始年月",
        trigger: "change",
      },
    ],
    endDate: [
      {
        required: true,
        message: "请选择截止年月",
        trigger: "change",
      },
    ],
    grantTime: [
      {
        required: true,
        message: "请选择发放日期",
        trigger: "change",
      },
    ],
    donorId: [
      {
        required: true,
        message: "请选择捐助人",
        trigger: "change",
      },
    ],
    amount: [
      {
        required: true,
        message: "请填写捐赠金额",
        trigger: "change",
      },
    ],
  };
}
