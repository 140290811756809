






















































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import CancelOrder from "@/views/studentAid/orders/cancel.vue";
import ConfirmOrder from "@/views/studentAid/orders/confirm.vue";
import BatchCancel from "@/views/studentAid/orders/batchCancel.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import {OrderStatus, StudentDonorDto} from "@/api/appService";


@Component({
  name: "donationIncomeList",
  components: {
    PagedTableView,
    AbSelect,
    CancelOrder,
    ConfirmOrder,
    BatchCancel,
    ExportButton
  },
})
export default class DonationIncomeList extends Vue {
  queryForm: any = {
    studentName: undefined,
    studentNo: undefined,
    donorName: undefined,
    successStartTime: undefined,
    successEndTime: undefined,
    orderStatus: OrderStatus.NotPay
  };
  editLinkId = 0;
  recordIds = [];
  selectionList = [];

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows;
  }

  fetchData(params: any) {
    return api.studentBidOrder.getAll(params);
  }

  // 取消订单
  handleCancel(row: any) {
    this.editLinkId = row.id!;
    (this.$refs.cancelOrderForm as any).show = true;
  }

  // 完成订单
  handleConfirm(row: any) {
    this.editLinkId = row.id!;
    (this.$refs.confirmOrderForm as any).show = true;
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "bidOrderDetail",
      params: {
        id: row.id!.toString()
      }
    })
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }

  handleBatchCancel() {
    this.dataIds = [];
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请选择至少一条数据!",
      });
      return;
    }
    this.$confirm("确认批量撤销吗?", "提示").then(async () => {
      let ids: any = [];
      this.selectionList.forEach((item: any) => {
        ids.push(item.id);
      });
      this.recordIds = ids;
      (this.$refs.batchCancelOrderForm as any).show = true;
    });
  }
}
