




























import {Component, Vue, Prop, Watch, Ref} from "vue-property-decorator";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import {StudentAidGrantRecordDto, UserDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  name: "AssociateRegisterAccount",
  components: {PagedTableView}
})
export default class AssociateRegisterAccount extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: number;

  show = false;
  defaultData: StudentAidGrantRecordDto = {};
  form: StudentAidGrantRecordDto = {...this.defaultData};


  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      (this.$refs.pagedTable as any).refreshData();
      const loading = this.$loading({
        target: ".el-dialog",
      });

      // console.log("dataId=" + this.dataId)
      await this.fetchData();

    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  // 获取表数据
  async fetchData() {
    return await api.studentAidReport.getAllStudentAidGrantRecord({bidId: this.dataId});
  }

  cancel() {
    this.show = false;
  }

}
