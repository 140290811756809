
























import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {CancelOrderInput} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditAnchor",
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: CancelOrderInput = {
    orderId: 0,
    cancelReason: ''
  };

  show = false;
  form: CancelOrderInput = { ...this.defaultData };

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      if (this.dataId) {
        this.form.orderId=this.dataId;
        setTimeout(() => {
          loading.close();
        }, 200);
      } else {
        this.form = { ...this.defaultData };
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.studentBidOrder.cancelOrder({body: this.form});
        this.show = false;
        this.$message.success("取消成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    cancelReason: [
      {
        required: true,
        message: "请输入取消原因",
        trigger: "change",
      },
    ],
  };
}
