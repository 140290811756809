






























import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  CmsAnchorDto,
  CmsAnchorCreateOrUpdateDto,
  StudentDtoPagedResultDto,
  StudentDto,
  ChangeStudentInput, BatchGrantInput
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditAnchor",
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataIds!: number[];

  defaultData: BatchGrantInput = {
    ids: [],
    grantTime: undefined,
  };

  show = false;
  form: BatchGrantInput = { ...this.defaultData };

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      this.form = { ...this.defaultData };
      //
      setTimeout(() => {
        loading.close();
      }, 200);
    } else {
      this.form = { ...this.defaultData };
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.studentAidBid.batchGrant({body: {grantTime:this.form.grantTime,ids:this.dataIds}});
        this.show = false;
        this.$message.success("操作成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    grantTime: [
      {
        required: true,
        message: "请选择发放时间",
        trigger: "blur",
      },
    ],
  };
}
