

















































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";

import {
  BidDtoPagedResultDto,
  CmsAnchorDto,
  CmsAnchorDtoPagedResultDto, CmsContentDto,
  CreatePairingRecordInput,
  PairingRecordPagedResultRequestDto, SelectListItem, StudentAidBidStatus
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import EditLink from "@/views/studentAid/bid/edit.vue";
import BatchGrant from "@/views/studentAid/bid/batchGrant.vue";
import BatchCreate from "@/views/studentAid/bid/batchCreate.vue";

import ExportButton from "@/components/ExportButton/index.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";

@Component({
  name: "AnchorList",
  components: {
    BatchGrant,
    BatchCreate,
    PagedTableView,
    ExportButton,
    ImportExcel,
    EditLink
  },
})
export default class AnchorList extends Vue {
  queryForm = {
    studentName: '',
    studentNo: '',
    schoolName: '',
    bidStatus:undefined,
    bidStartDate:undefined,
  } as any;

  bidStatusList:SelectListItem[]=[
    {value:StudentAidBidStatus.UnPublish,text:'未发布'},
    {value:StudentAidBidStatus.Published,text:'已发布'},
    {value:StudentAidBidStatus.Subscript,text:'认捐中'},
    {value:StudentAidBidStatus.HasParing,text:'已结对'},
    {value:StudentAidBidStatus.HasGranted,text:'已发放'},

  ];
  selectionList = [];
  dataIds=[];
  editLinkId = 0;
  gradeList: any[] = [];

  created(): void{
    this.fetchEnumList();
  }

  fetchEnumList(): void {
    api.enumService
      .getValues({typeName: "StudentGrade"})
      .then((res: any) => {
        this.gradeList = res;
      });
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows;
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.studentAidBid.getAll(params);
  }
  // 编辑
  handleEdit(row: any) {
    this.editLinkId = row.id!;
    (this.$refs.editForm as any).show = true;

    console.warn("%cu must reWrite this method", "color:#0048BA;");
  }

  // 新建
  handleCreate() {
    this.editLinkId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // 跳转详情页
  handleDetail(item: any) {
    this.$router.push({
      name: "bidDetail",
      params: { id: item.id!.toString() }
    });
  }

  // 发布
  handlePublish(item: any) {
    this.$confirm("确定发布吗?", "提示").then(() => {
      api.studentAidBid.publish({ body: { id: item.id } }).then((res) => {
        this.$message.success("发布成功");
      });
    });
  }

  // 取消发布
  handleCancelPublish(item: any) {
    this.$confirm("确定取消发布吗?", "提示").then(() => {
      api.studentAidBid.cancelPublish({ body: { id: item.id } }).then((res) => {
        this.$message.success("取消成功");
      });
    });
  }

  // 删除
  handleDelete(item: any) {
    this.$confirm("确定删除吗?", "提示").then(() => {
      api.studentAidBid.delete( { id: item.id  }).then((res) => {
        this.$message.success("删除成功");
      });
    });
  }

  handleBatchCreate() {
    (this.$refs.batchCreateForm as any).show = true;
  }

  handleBatchGrant() {
    this.dataIds=[];
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请选择至少一条数据!",
      });
      return;
    }
    this.$confirm("确认批量发放吗?", "提示").then(async () => {
      let ids: any = [];
      this.selectionList.forEach((item: any) => {
        ids.push(item.id);
      });
      this.dataIds=ids;
      (this.$refs.batchGrantForm as any).show = true;
    });
  }

  handleBatchPublish() {
    this.dataIds=[];
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请选择至少一条数据!",
      });
      return;
    }
    this.$confirm("确认批量发布吗?", "提示").then(async () => {
      let ids: any = [];
      this.selectionList.forEach((item: any) => {
        ids.push(item.id);
      });
      this.dataIds=ids;
      await api.studentAidBid
        .batchPublish({
          body: { ids: ids },
        })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });

    });
  }

  handleBatchCancelPublish() {
    this.dataIds=[];
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请选择至少一条数据!",
      });
      return;
    }
    this.$confirm("确认批量取消发布吗?", "提示").then(async () => {
      let ids: any = [];
      this.selectionList.forEach((item: any) => {
        ids.push(item.id);
      });
      this.dataIds=ids;
      await api.studentAidBid
        .batchCancelPublish({
          body: { ids: ids },
        })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });

    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
